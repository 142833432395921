<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span class="firstTitle70">{{ $t('searchArea.applyDate') }}</span></div>
           <date-selector
             :class="'searchbar-content'"
             @setStartDate="setStartDate"
             @setEndDate="setEndDate"
             :startDefault="startDefault"
             :endDefault="endDefault"
             :configs="dateConfigs"
             :isOldYn="true"
             @setOldYn="setOldYn" :oldYn="reqData.oldYn"/>
         </div>
      </div>
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span class="firstTitle70">{{ $t('company.venderType') }}</span></div>
           <select class="mr-5" required v-model="reqData.vendor">
             <option value="">{{ $t('searchArea.total') }}</option>
             <template v-for="item in vendorList" :key="item.code">
               <option :value="item.code">{{item.text}}</option>
             </template>
           </select>
         </div>
         <div class="searchbar-content">
            <div class="title"><span>{{ $t('table.head.betType') }}</span> :</div>
            <select class="mr-5" required v-model="reqData.vendorCode">
             <option value="">{{ $t('searchArea.total') }}</option>
             <template v-for="item in gameAllList" :key="item.code">
                <option :value="item.code">{{item.codeName}}</option>
             </template>
            </select>
         </div>
         <div class="searchbar-content">
            <div class="title"><span>{{ $t('table.head.insureBetYN') }}</span></div>
            <div>
               <select class="mr-5" required v-model="reqData.isBiBet">
                <option value="">{{ $t('searchArea.total') }}</option>
                <option value="Y">Y</option>
                <option value="N">N</option>
               </select>
               <input type="text" class="mr-5" v-model="reqData.memId" :placeholder="$t('searchArea.sch')" />
               <button class="btn-search" type="button" @click="pageSeach">
                <i class="fas fa-search"></i>
               </button>
            </div>
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container fx-col-sbw">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <!-- <table-head :headInfo="headInfo" /> -->
          <thead>
            <tr>
              <th>{{ $t('table.head.idx') }}</th>
              <th>{{ $t('table.head.userID') }}<br/>({{ $t('table.head.companyId') }})</th>
              <th>{{ $t('table.head.vender') }}</th>
              <th>{{ $t('table.head.betType') }}</th>
              <th>{{ $t('table.head.gameUniqueId') }}<br/>{{ $t('table.head.sportj') }}<br/>{{ $t('searchArea.endDate') }}</th>
              <th>{{ $t('table.head.betAmt') }}</th>
              <th>{{ $t('table.head.wl') }}</th>
              <!--th>{{ $t('table.head.insureBetYN') }}</th-->
              <th class="wfix">{{ $t('table.head.insureBet') }}</th>
              <th class="wfix">{{ $t('table.head.credit') }}</th>
              <th class="wfix">{{ $t('table.head.feeRate') }}</th>
              <th class="wfix">{{ $t('table.head.point') }}</th>
            </tr>
            <tr>
              <!--보험베팅>
              <th>{{ $t('table.head.insurBetApplyStd') }}</th>
              <th>{{ $t('table.head.insureBetAmt') }}</th>
              <th>{{ $t('table.head.insureRate') }}</th>
              <크레딧
              <th>{{ $t('table.head.preBet') }}</th>
              <th>{{ $t('table.head.pm') }}</th>
              <th>{{ $t('table.head.aftBet') }}</th>
              <수수료>
              <th>{{ $t('table.head.std') }}</th>
              <th>{{ $t('table.head.game') }}</th>
              <th>{{ $t('table.head.applied') }}</th>
              <포인트>
              <th>{{ $t('table.head.preSave') }}</th>
              <th>{{ $t('table.head.collectPointAmt') }}</th>
              <th>{{ $t('table.head.aftSave') }}</th-->
            </tr>
          </thead>
          <tbody>
            <template v-if="tableData.length!==0">
              <tr v-for="item of tableData" :key="item.idx">
                <td class="roboto">{{item.idx}}</td>
                <td>
                  <template v-if="siteInfo.siteId === item.botSiteId">
                    <button type="button" class="fc-id btn-link" @click="detailOpen('user',item.botMemId)" >{{item.botMemId}}</button>
                  </template>
                  <template v-else>
                      <button type="button" class="fc-id btn-link" @click="detailOpen('company',item.botSiteId)" >{{item.botSiteId}}</button>
                  </template>
                </td>
                <td>{{ getVendorName(item.vendor) }}</td>
                <td>{{item.vendorName}}</td>
                <td class="wfix">
                   <span class="twrap" @click="item.tid = true">
                      <em class="text roboto">{{item.trxId}}</em>
                      <em class="roboto">{{item.betRegDt}}</em>
                      <em class="roboto">{{item.regDt}}</em>
                   </span>
                   <div v-if="item.tid" class="tidBox">
                     {{item.trxId}}<a :href="'#'" @click="item.tid = false"><i class="fa fa-times" aria-hidden="true" style="margin-left: 8px;"></i></a>
                   </div>
                </td>
                <td class="roboto">{{numberWithCommas (item.betAmt)}}</td>
                <td :class="Number(item.betWlAmt) < 0?'fc-blue':'fc-red'" class="roboto">{{numberWithCommas(item.betWlAmt)}}</td>
                <!--td>{{item.isBiBet}}</td-->
                <td>
                   <ul class="tint wfix">
                      <li>
                        <em>보험률(%,금액)</em>
                        <span class="roboto">{{numberWithCommas(item.insuredAmt)}}</span>
                      </li>
                      <li>
                        <em>{{ $t('table.head.insureBetAmt') }}</em>
                        <span class="roboto">{{numberWithCommas (item.biBetAmt)}}</span>
                      </li>
                      <li>
                        <em>{{ $t('table.head.insurewl') }}</em>
                        <span class="roboto">{{item.insuredAmt ? numberWithCommas(item.insuredAmt):'-'}}</span>
                      </li>
                   </ul>
                </td>
                <!--td>{{numberWithCommas(item.baseInsureBetAmt)}}</td>
                <td>{{numberWithCommas (item.biBetAmt)}}</td>
                <td>{{item.insureRate?item.insureRate:'-'}}</td-->

                <td>
                   <ul class="tint wfix">
                      <li>
                        <em>{{ $t('table.head.preBet') }}</em><span class="roboto">{{numberWithCommas(item.bfMyCredit)}}</span>
                      </li>
                      <li>
                        <em>{{ $t('table.head.pm') }}</em><span :class="Number(item.myCreditAmt) < 0?'fc-blue':'fc-red'" class="roboto">{{numberWithCommas(item.myCreditAmt)}}</span>
                      </li>
                      <li>
                        <em>{{ $t('table.head.aftBet') }}</em><span class="roboto">{{numberWithCommas(Number(item.bfMyCredit)+Number(item.myCreditAmt))}}</span>
                      </li>
                   </ul>
                </td>

                <!--td>{{numberWithCommas(item.bfMyCredit)}}</td>
                <td :class="Number(item.myCreditAmt) < 0?'fc-red':'fc-blue'">{{numberWithCommas(item.myCreditAmt)}}</td>
                <td>{{numberWithCommas(Number(item.bfMyCredit)+Number(item.myCreditAmt))}}</td-->

                <td>
                   <ul class="tint wfix">
                      <li>
                        <em>{{ $t('table.head.std') }}</em><span class="roboto">{{Number(item.creditRate).toFixed(2)}} %</span>
                      </li>
                      <li>
                        <em>{{ $t('table.head.game') }}</em><span :class="Number(item.creditSubPointAmt) < 0?'fc-blue':'fc-red'" class="roboto">{{Number(item.pointRate).toFixed(2)}} %</span>
                      </li>
                      <li>
                        <em>{{ $t('table.head.applied') }}</em><span :class="(Number(item.creditRate) - Number(item.pointRate))<0?'fc-blue':'fc-red'" class="roboto">{{Number(item.creditRate) - Number(item.pointRate)}} %</span>
                      </li>
                   </ul>
                </td>

                <!--td>{{Number(item.creditRate).toFixed(2)}} %</td>
                <td>{{Number(item.pointRate).toFixed(2)}} %</td>
                <td :class="(Number(item.creditRate) - Number(item.pointRate))<0?'fc-red':'fc-blue'">{{Number(item.creditRate) - Number(item.pointRate)}} %</td-->

                <td>
                   <ul class="tint wfix">
                      <li>
                        <em>{{ $t('table.head.preSave') }}</em><span class="roboto">{{numberWithCommas(item.preCreditSubPoint)}}</span>
                      </li>
                      <li>
                        <em>{{ $t('table.head.collectPointAmt') }}</em><span :class=" Number(item.creditSubPointAmt) < 0 ? 'fc-blue':'fc-red' " class="roboto">{{numberWithCommas(item.creditSubPointAmt)}}</span>
                      </li>
                      <li>
                        <em>{{ $t('table.head.aftSave') }}</em><span class="roboto">{{numberWithCommas(Number(item.preCreditSubPoint) + Number(item.creditSubPointAmt))}}</span>
                      </li>
                   </ul>
                </td>

                <!--td>{{numberWithCommas(item.preCreditSubPoint)}}</td>
                <td :class="Number(item.creditSubPointAmt) < 0?'fc-red':'fc-blue'">{{numberWithCommas(item.creditSubPointAmt)}}</td>
                <td>{{numberWithCommas(item.creditSubPoint)}}</td-->
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="11">{{ $t('txt.noData') }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import DateSelector from '@/components/common/DateSelector'
import { getSiteData } from '@/libs/auth-helper'
import { companySubPointMyList } from '@/api/company.js'
import Pagination from '@/components/common/Pagination'
import lodash from 'lodash'
import { mapState } from 'vuex'
import { getVendorList } from '@/api/common'
import { VENDOR_NAME } from '@/libs/constants'
import Memo from '@/components/common/memo'

export default {
  name: 'CreditPointList',
  components: {
    DateSelector,
    Pagination,
    Memo
  },
  watch: {
    $route: {
      async handler () {
        const status = this.$route.params.status
        console.log(status)
        await this.setTableData(1, status)
      }
    },
    gameList () {
      console.log('-----')
      console.log(this.gameList)

      if (this.gameList) {
        this.gameList.forEach(group => {
          const game = group.child
          game.forEach(item => {
            this.gameAllList.push({
              codeName: this.$i18n.locale === 'ko' ? item.codeName : item['codeName' + this.$i18n.locale.toUpperCase()],
              code: item.code
            })
          })
        })
      }
    }
  },
  data: function () {
    return {
      reqData: {
        startDate: '',
        endDate: '',
        page: 1,
        count_per_list: '30',
        memId: '',
        siteId: '',
        vendorCode: '',
        vendor: '',
        isBiBet: '',
        oldYn: 'N'
      },
      vendorList: [],
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      pageInfo: {},
      siteInfo: {},
      tableData: [],
      tid: false,
      gameAllList: []
    }
  },
  computed: {
    ...mapState([
      'gameList',
      'siteData'
    ])
  },
  methods: {
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    pageSeach () {
      this.setTableData(1)
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    async getSubPointList () {
      const req = lodash.cloneDeep(this.reqData)
      const res = await companySubPointMyList(req)
      console.log(res)
      res.resultCode = '0'
      if (res.resultCode === '0') {
        return res.data
      } else {
        return false
      }
    },
    async setTableData (pageNum) {
      this.emitter.emit('Loading', true)
      if (pageNum) {
        this.reqData.page = pageNum
      }
      const data = await this.getSubPointList()
      this.emitter.emit('Loading', false)
      if (!data) {
        console.error('point list response error')
        return
      }
      this.tableData = data.list
      // console.log(this.tableData)
      const pageInfo = data.pageInfo
      if (pageInfo) {
        this.pageInfo = this.setPageInfo(pageInfo)
      }
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = this.getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
        _date.setSeconds(59)
      }
      this.endDefault = _date
      this.reqData.endDate = this.getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    }
  },
  async created () {
    this.siteInfo = this.getSiteInfo()
    const vendorList = await getVendorList()

    this.vendorList = []
    vendorList.forEach(item => {
      this.vendorList.push({
        code: item,
        text: this.getVendorName(item)
      })
    })

    await this.setGameList()
    console.log(this.vendorList)
    console.log(this.gameListDefault)
    console.log(this.gameList)
    this.setStartDate()
    this.setEndDate()
    await this.setTableData()
  }
}
</script>

<style scoped>
.wfix {width: 11%;}
.twrap {display: flex;flex-direction: column;align-items: center;gap: 3px;color: #898989;}
.statusColor {
  font-weight: 700;
}
.statusColor.apply {
  color: #ef8c1d;
}
.statusColor.approve {
  color: blue;
}
.statusColor.refuse {
  color: red;
}
.statusColor.wait {
  color: green;
}
select option[disabled] {display: none;}
.text {display: block;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 132px;}
.text:hover {text-decoration: underline;cursor: pointer;}
.tidBox {border: 1px solid #000;border-radius: 3px;background: #fff;padding: 10px;position: absolute; display: flex;}
.tidBox > a {display: flex;align-items: center;justify-content: end;}
.mainTable tbody:last-child .tint tr:last-child {border-bottom: 0;}
.tint {padding: 0 10px;padding: 0 10px;display: flex;flex-direction: column;gap: 5px;width: 145px;}
.tint li {display: flex; justify-content: space-between;margin-top: 3px;}
.tint em {font-size: 13px;}
</style>
